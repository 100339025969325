<template>
    <form class="authorization-form" ref="loginForm" @submit.prevent="validate">
        <div class="title">{{$t('Authorization')}}</div>
        <div class="inputs-container">
            <InputComponent
                :showLabel='true'
                :placeholder="$t('Username')"
                name="login"
                v-model="formData.login"
                :error="errors.login"
            />
            <InputComponent
                :showLabel='true'
                :placeholder="$t('Password')"
                :error="errors.password"
                v-model="formData.password"
                name="password"
                :type="type"
                class="password-input"
                ref="passwordInput"
            />
            <button type="button" class="show-password material-icons" @click.prevent="togglePassword">{{ type !== 'password' ? 'visibility_off' : 'visibility' }}</button>
        </div>
        <div class="bottom-btn">
            <vue-recaptcha
                size="invisible"
                ref="recaptcha"
                :sitekey="recaptchaKey"
                :loadRecaptchaScript="true"
                @verify="submitForm"
                @expired="onCaptchaExpired"
            />
            <div class="row-bottom">
                <div>
                    <CheckboxComponent :value="formData.remember_me" :label="$t('Remember me')" @change="rememberMe"/>
                </div>
                <div class="remind-password">
                    <router-link to="/reset-password">{{$t('Remind password')}}</router-link>
                </div>
            </div>
            <button class="login-btn" type="submit">{{$t('Login')}}</button>
        </div>
    </form>
</template>

<script>
import InputComponent from "./InputComponent";
import VueRecaptcha from 'vue-recaptcha';
import CheckboxComponent from "./CheckboxComponent";

export default {
    name: "AuthorizationForm",
    components: {
        InputComponent,
        VueRecaptcha,
        CheckboxComponent
    },
    props: {
        language: {
            type: String,
            required: true
        },
        recaptchaKey: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            formData: {
                login: '',
                password: '',
                'g-recaptcha-response': '',
                remember_me: false
            },
            errors: {
                login: null,
                password: null,
            },
            type: 'password'
        }
    },
    watch: {
        language() {
            this.errors = {};
        }
    },
    methods: {
        togglePassword() {
            this.type = this.type === 'password' ? 'text' : 'password';
        },
        rememberMe(){
            this.formData.remember_me = !this.formData.remember_me
        },
        submitForm(token) {
            this.formData["g-recaptcha-response"] = token;

            this.api.authentication.sendLoginData(this.formData, this.$i18n.locale).then(resp => {
                if(Object.keys(resp.user || {}).length) {
                    this.$store.dispatch('authSet', resp.user);
                    if (this.role.getRoleName() === 'SuperAdmin'){
                        this.$router.push('/admin/users');
                    } else if (this.role.getRoleName() === 'GovEmployee'){
                        this.$router.push('/statistics');
                    } else if (this.role.getRoleName() === 'Distributor'){
                        this.$router.push('/home/distributors');
                    } else if (this.role.getRoleName() === 'NetworkAdministrator'){
                        this.$router.push('/home/network');
                    } else if (this.role.getRoleName() === 'CinemaEmployee'){
                        this.$router.push('/home/cinema');
                    } else {
                        this.$router.push('/');
                    }
                }
            }).catch(this.handleError);

            this.onCaptchaExpired();
        },
        handleError(resp) {
            this.errors = {}
            if (resp.statusCode === 422) {
                this.errors = resp.messages;
            }
        },
        validate() {
            this.$refs.recaptcha.execute()
        },
        onCaptchaExpired() {
            this.$refs.recaptcha.reset()
        }
    }
}
</script>

<style scoped lang="scss">
.authorization-form {
    display: flex;
    //height: 75%;
    position: relative;
    flex-direction: column;
    justify-content: center;
    margin-right: 40px;
    margin-top: 30px;
    @media (max-width: 768px) {
        margin-top: 50px;
        margin-right: 0;
    }

    .title {
        font-size: 30px;
        font-weight: 600;
        color: #141213;
        margin-bottom: 80px;
    }

    .inputs-container {
        position: relative;
        @media (max-width: 768px) {
            width: 100%;
            max-width: 100%;
        }

        .form-group {
            max-width: 360px;
            @media (max-width: 768px) {
                width: 100%;
                max-width: 100%;
            }

            &:not(:first-child) {
                margin-top: 25px;
            }
        }

        max-width: 360px;

    }

    .bottom-btn {
        max-width: 360px;

        margin-top: 36px;

        .row-bottom {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        input {
            height: 16px;
            width: 16px;
            margin-right: 12px;
        }

        .custom-checkbox {
            position: absolute;
            z-index: -1;
            opacity: 0;
        }

        .custom-checkbox + label {
            display: inline-flex;
            align-items: center;
            user-select: none;
        }

        .custom-checkbox + label::before {
            content: '';
            display: inline-block;
            width: 1em;
            height: 1em;
            flex-shrink: 0;
            flex-grow: 0;
            border: 1px solid #adb5bd;
            border-radius: 0.25em;
            margin-right: 0.5em;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 50% 50%;
        }

        .custom-checkbox:checked + label::before {
            border-color: #0b76ef;
            background-color: $color_lightpink;
        }

        .checkbox-label {
            font-size: 18px;
            color: #141213;
        }

        .remind-password {
            font-size: 18px;
            color: $color_lightpink;
            text-decoration: underline;
            a{
                color: $color_lightpink;
            }
        }

        .login-btn {
            width: 195px;
            height: 45px;
            border-radius: 2px;
            background-color: $color_darkblue;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            font-weight: 900;
            letter-spacing: 2px;
            text-align: center;
            margin-top: 37px;
            border: none;
            text-transform: uppercase;
            color: white;
            &:hover{
                box-shadow: 0 4px 10px 0 rgba(53, 0, 212, 0.25);
                color: $color_lightpink;
            }

            &:focus, &:active {
                outline: none;
            }
        }
    }

    .show-password {
        position: absolute;
        border: 0;
        background-color: transparent;
        color: $color_darkblue;
        right: 8px;
        bottom: 13px;
        cursor: pointer;
    }

    ::v-deep {
        .form-group__error {
            font-size: 13px;
            color: #FF496D;
            position: absolute;
            right: 0;
            bottom: auto;
            max-width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .form-control.is-invalid {
            background-image: none;
        }
    }
}
</style>
