<template>
    <div class="login-form-container">
        <div class="row">
            <div class="col-8 image-block-container">
                <div class="image-block">
                    <div class="img">
                        <img src="@/assets/img/KK_authorization_picture.svg" alt="">
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4 col-sm-12 main-block">
                <div class="language">
                    <div class="language-link" :class="{active: $i18n.locale === 'ua'}" @click="changeLocale('ua')">
                        UA
                    </div>
                    <span>/</span>
                    <div class="language-link" :class="{active: $i18n.locale === 'en'}"  @click="changeLocale('en')">
                        EN
                    </div>
                </div>
                <div class="logo">
                    <img src="@/assets/img/logo_EKK.svg" alt="logo">
                    <div class="description">
                        Єдиний Кіно Квиток
                    </div>
                </div>
                <AuthorizationForm :language="$i18n.locale" :recaptchaKey="recaptchaKey" v-if="this.$route.name === 'login'" />
                <ResetPasswordForm
                    :language="$i18n.locale"
                    :recaptchaKey="recaptchaKey"
                    v-else-if="this.$route.name === 'reset-password'"
                />
            </div>
        </div>
    </div>
</template>

<script>
import AuthorizationForm from "../../components/ui/forms/AuthorizationForm";
import ResetPasswordForm from "../../components/ui/forms/ResetPasswordForm";

export default {
    name: "LoginForm",
    data () {
        return {
            language: 'ua',
            recaptchaKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'
        }
    },
    components:{
        AuthorizationForm,
        ResetPasswordForm
    },
    methods: {
        changeLocale(lang) {
            this.$store.dispatch('setLocale', lang);
        }
    }
}
</script>


<style scoped lang="scss">
.login-form-container{
    width: 100%;
    overflow-x: hidden;
    //max-height: 100%;
    background-color: white;
    min-height: 100vh;
    .image-block-container{
        padding-right: 18px;
        @media (max-width: 768px) {
            display: none;
        }
    }
    .image-block{
        min-height: 100vh;
        background-color: #2d5ca6;
        justify-content: center;
        align-items: center;
        display: flex;
        .img{
            max-width: 695px;
            max-height: 609px;
            width: 47%;
        }
        img{
            width: 100%;
        }
    }
    .main-block{
        overflow-x: hidden;
        @media (max-width: 768px) {
            padding: 35px;
        }
        .logo{
            height: 20%;
            display: flex;
            padding-top: 30px;
            box-sizing: border-box;
            img{
                height: 67px;
                width: 67px;
            }
            .description{
                font-size: 20px;
                font-weight: 900;
                color: #0c0032;
                text-transform: uppercase;
                padding-left: 30px;
                padding-top: 18px;
            }
        }
        .language{
            display: flex;
            position: absolute;
            top: 30px;
            right: 30px;

            @media (max-width: 1034px) {
                top: 7px;
            }

            .language-link{
                cursor: pointer;
                font-size: 14px;
                font-weight: 900;
                color: #cad9f3;
            }
            .active{
                color: $color_lightpink;
            }
            span{
                margin: 0 5px;
                color: #cad9f3;
            }
        }
    }
}
</style>
