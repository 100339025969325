<template>
    <form class="reset-password-form" ref="resetPasswordForm" @submit.prevent="validate">
        <div class="title">
            <span v-if="language == 'ua'">Зміна паролю</span>
            <span v-else>Change password</span>
        </div>
        <div class="inputs-form">
            <InputComponent
                :showLabel='true'
                placeholder="E-mail"
                name="email"
                type="email"
                ref="emailInput"
                :class="{error : this.errors.email}"
                :error="errors.email ? errors.email : ''"
                v-model="formData.email"
            />
        </div>
        <div class="success-text" :class="{visible:successFull}">
            <span>
                {{$t('A password confirmation email has been sent to your email address.')}}
                {{$t('If it wasn\'t you, ignore this letter')}}
            </span>
        </div>
        <div class="btn-container">
            <vue-recaptcha
                size="invisible"
                ref="recaptcha"
                :sitekey="recaptchaKey"
                :loadRecaptchaScript="true"
                @verify="submitForm"
                @expired="onCaptchaExpired"
            />
            <button type="submit" class="get-new-password">
                <span v-if="language == 'ua'">Змінити</span>
                <span v-else>Change</span>
            </button>
            <router-link class="go-back" to="/login">
                <span v-if="language == 'ua'">Скасувати</span>
                <span v-else>Cancel</span>
            </router-link>
        </div>
    </form>
</template>

<script>
import InputComponent from "./InputComponent";
import VueRecaptcha from 'vue-recaptcha'



export default {
    name: "ResetPasswordForm",
    components: {
        VueRecaptcha,
        InputComponent
    },
    props: {
        language: {
            type: String,
            required: true
        },
        recaptchaKey: {
            type: String,
            required: true
        }
    },
    watch: {
        language() {
            this.errors = {};
        }
    },
    data() {
        return{
            successFull: false,
            errors: {},
            formData: {
                email: '',
                'g-recaptcha-response': ''
            }
        }
    },
    methods: {
        async submitForm(token){
            this.formData["g-recaptcha-response"] = token;

            let resp = await this.api.authentication.sendResetPassword(this.formData, this.language).catch(this.handleError);
            if (resp) {
              this.successFull = true
            }
            this.onCaptchaExpired();
        },
        handleError(resp){
            this.errors = {}
            if (resp.statusCode === 422) {
              this.errors = resp.messages
            }
        },
        validate() {
            this.$refs.recaptcha.execute()
        },
        onCaptchaExpired() {
            this.$refs.recaptcha.reset()
        }

    }
}
</script>

<style scoped lang="scss">
    .reset-password-form{
        display: flex;
        height: 75%;
        position: relative;
        flex-direction: column;
        justify-content: center;
        margin-right: 40px;
        max-width: 360px;
        @media (max-width: 768px){
            max-width: 100%;
            margin-right: 0;
        }

        .title{
            font-size: 30px;
            font-weight: 600;
            color: #141213;
            margin-bottom: 80px;
          @media (max-width: 768px) {
            margin-top: 30px;
          }
        }

        .inputs-form{
            position: relative;
            max-width: 360px;
            @media (max-width: 768px){
                max-width: 100%;
            }
            ::v-deep{
              .error input{
                border: 1px solid #FF496D;
              }
            }
        }

        .success-text, .email-error{
            font-size: 18px;
            line-height: 1.5;
            color: #646e78;
            max-width: 360px;
            margin-top: 17px;
        }
        .success-text{
            opacity: 0;
            cursor: default;
        }
        .visible{
            opacity: 1;
            cursor: auto;
        }
        .email-error{
          font-size: 12px;
          color: #FF496D;
        }
        .btn-container{
            display: flex;
            margin-top: 35px;
            align-items: center;
            @media (max-width: 768px) {
              flex-direction: column;
                margin-top: 5px;
            }
            .get-new-password{
                width: 195px;
                height: 45px;
                border-radius: 2px;
                box-shadow: 0 4px 10px 0 rgba(53, 0, 212, 0.25);
                background-color: $color_darkblue;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 12px;
                font-weight: 900;
                letter-spacing: 2px;
                text-align: center;
                color: white;
                border: none;
                text-transform: uppercase;
                margin-right: 20px;
                &:hover{
                    color: $color_lightpink;
                }
                @media (max-width: 768px) {
                  margin-bottom: 20px;
                    margin-right: 0;
                }
            }
            .go-back{
                font-size: 12px;
                font-weight: 900;
                letter-spacing: 2px;
                color: #141213;
                text-transform: uppercase;
            }
        }
        ::v-deep{
            .form-control.is-invalid{
                background-image: none;
            }
        }
    }
</style>
